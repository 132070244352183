<ng-container *ngIf="internalPages; else defaultPage">
  <div class="internal-header">
    <app-landing-page-header [smallView]="smallView" [user]="user" [blackLogo]="true">
    </app-landing-page-header>
  </div>
</ng-container>
<ng-template #defaultPage>
  <div class="header-section sparro-white">
    <app-landing-page-header [smallView]="smallView" [user]="user"> </app-landing-page-header>
    <div fxLayout="row" fxLayoutAlign="center center" class="container">
      <p class="margin-0 title">Platform Policies</p>
    </div>
  </div>
</ng-template>
<div class="route-container">
  <router-outlet></router-outlet>
</div>
<div
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="64px"
  class="relative cards-container">
  <p class="text-center font-bolder section-title margin-0">Policies for using services on UNI</p>
  <div class="cards-inner-container">
    <ng-container *ngFor="let card of policyCards">
      <div class="cards" fxLayout="column" fxLayoutGap="32px">
        <div fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center">
          <uni-image
            [src]="card.img"
            class="icon"
            [alt]="card.title + '-img'"
            classes="width-100 height-100">
          </uni-image>
          <p class="margin-0 font-bold title">{{ card.title }}</p>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <ng-container *ngFor="let link of card.subSections">
            <a [href]="link.url" class="no-decor subtitle">
              <span [class]="currentUrl == link.url ? 'active-link' : 'sparro-grey-800'">{{
                link.text
              }}</span>
            </a>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<uni-public-footer [topMargin]="false"></uni-public-footer>
