import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PoliciesComponent } from './policies.component';

const routes: Routes = [
  {
    path: '',
    component: PoliciesComponent,
    children: [
      {
        path: 'business',
        loadChildren: () => import('./business/business.module').then(m => m.BusinessModule)
      },
      {
        path: 'community',
        loadChildren: () => import('./community/community.module').then(m => m.CommunityModule)
      },
      {
        path: 'licensing',
        loadChildren: () => import('./licensing/licensing.module').then(m => m.LicensingModule)
      },
      {
        path: 'general-terms',
        loadChildren: () =>
          import('./general-terms/general-terms.module').then(m => m.GeneralTermsModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PoliciesRoutingModule {}
