import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { environment } from '@about/env/environment';
import { User } from '@sparro/app/core/models/user.model';
import { UserService } from '@sparro/app/core/api/user/user.service';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss']
})
export class PoliciesComponent implements OnInit {
  policyCards = [
    {
      img: `https://uni-common-assets.s3.amazonaws.com/policy/icon-business-case.svg`,
      title: `Business`,
      subSections: [
        {
          text: `Payment liability`,
          url: `${environment.aboutUrl}policy/business/payment-liability`
        },
        {
          text: `Sponsorship policy`,
          url: `${environment.aboutUrl}policy/business/sponsorship`
        },
        {
          text: `Sponsorship terms`,
          url: `${environment.aboutUrl}policy/business/sponsorship-terms`
        }
      ]
    },
    {
      img: `https://uni-common-assets.s3.amazonaws.com/policy/icon-group-chat.svg`,
      title: `Community`,
      subSections: [
        {
          text: `Acceptable use policy`,
          url: `${environment.aboutUrl}policy/community/acceptable-use`
        },
        {
          text: `Community guidelines`,
          url: `${environment.aboutUrl}policy/community/guidlines`
        },
        {
          text: `Conduct policy`,
          url: `${environment.aboutUrl}policy/community/conduct`
        }
      ]
    },
    {
      img: `https://uni-common-assets.s3.amazonaws.com/policy/icon-form.svg`,
      title: `General Terms`,
      subSections: [
        {
          text: `Cookie policy`,
          url: `${environment.aboutUrl}policy/general-terms/cookie`
        },
        {
          text: `Privacy policy`,
          url: `${environment.aboutUrl}policy/general-terms/privacy`
        },
        {
          text: `Terms of service`,
          url: `${environment.aboutUrl}policy/general-terms/terms-of-service`
        }
      ]
    },
    {
      img: `https://uni-common-assets.s3.amazonaws.com/policy/icon-no-copyright.svg`,
      title: `Licensing`,
      subSections: [
        {
          text: `Copyright policy`,
          url: `${environment.aboutUrl}policy/licensing/copyright`
        },
        {
          text: `Trademark`,
          url: `${environment.aboutUrl}policy/licensing/trademark`
        }
      ]
    }
  ];

  smallView: boolean = false;
  user: User;
  internalPages: boolean = false;
  currentUrl: string = '';

  constructor(
    public breakpointObserver: BreakpointObserver,
    private userService: UserService,
    public metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.getAuthUser();
    this.updateMetaInfo();
    this.currentUrl = window.location.href;
    this.initializeBreakpoints();
    this.internalPages = Boolean(window.location.href.split('policy')[1].length);
  }

  getAuthUser(): void {
    this.userService.userInitialized.subscribe((initialized: boolean) => {
      if (initialized) {
        this.userService.user.subscribe((user: User) => {
          this.user = user;
        });
      }
    });
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  updateMetaInfo(): void {
    const title = 'Platform policies - UNI';
    const description = `Policies for using services on UNI`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/logo/uni/uni_logo_393x393.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
